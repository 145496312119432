<template>
<div>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Sınıf Ortalaması
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Doğru Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.correct}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Yanlış Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.wrong}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Boş Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.empty}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Puan:
                        <span style="font-weight: bold;">{{ort.point}} / 100</span>
                    </div>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>

        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <template #cell(status)="data">
                <b-badge pill :variant="getExamStatus(data.item.status)">
                    {{ getExamStatusText(data.item.status) }}
                </b-badge>
            </template>

            <template #cell(action)="data">
                <b-button block variant="flat-success" class="font-weight-bolder mb-12" @click="getTestPreview(data.item)">Testi Gör</b-button>
            </template>

            <!-- Id -->
            <template #cell(username)="data">
                <span class="font-weight-bolder mb-12">{{
                    (data.item.user!=null)?
                    data.item.user.firstName+' '+data.item.user.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
            </template>

        </b-table>
    </b-card>
    <b-modal size="xl" title="Test Detayları" centered cancel-variant="outline-secondary" no-close-on-backdrop v-model="openDetailsModal" hide-footer>
        <b-card no-body>
            <b-button variant="success" @click="getExamResult()" block>İNDİR</b-button>
            <b-card-body>
                <b-row>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Doğru Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.correct}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Yanlış Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.wrong}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Boş Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.empty}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Puan:
                            <span style="font-weight: bold;">{{testPreviewData.point}}</span>
                        </div>
                    </b-col>

                </b-row>
            </b-card-body>

            <b-table :items="testPreviewData.questions" responsive :fields="testQuestionFields" class="mb-0">
                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <!-- Id -->
                <template #cell(studentAnswer)="data">
                    <span class="font-weight-bolder mb-12">{{
             (data.item.answers.filter(x=>x.isSelected==true).length>0)?data.item.answers.filter(x=>x.isSelected==true)[0].text:"Boş Bıraktı"
              }}</span>
                </template>
                <template #cell(trueAnswer)="data">
                    <span class="font-weight-bolder mb-12">{{
                    data.item.answers.filter(x=>x.isTrue==true)[0].text
              }}</span>
                </template>
            </b-table>

        </b-card>
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx';
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay
    },
    props: ["lessonId", "childLessonId", "topicId", "videoGroupId", "testId", "classId"],
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'username', label: 'Öğrenci Adı Soyadı' },
                { key: 'class', label: 'Şube' },
                { key: 'title', label: 'Test' },
                { key: 'startDate', label: 'Başlama Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'status', label: 'Durum' },
                { key: 'correct', label: 'Doğru' },
                { key: 'wrong', label: 'Yanlış' },
                { key: 'empty', label: 'Boş' },
                { key: 'point', label: 'Puan' },
                { key: 'action', label: 'İşlem' },
            ],
            testQuestionFields: [
                { key: 'id', label: 'Ref' },
                { key: 'gains', label: 'Kazanım' },
                { key: 'studentAnswer', label: 'Öğrencinin Cevabı' },
                { key: 'trueAnswer', label: 'Doğru Cevap' }
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            users: [],
            ort: {
                totalOrt: 0,
                correct: 0,
                wrong: 0,
                empty: 0,
                point: 0
            },
            openDetailsModal: false,
            testPreviewData: {}
        }
    },
    created() {
        this.getTestData();
    },
    watch: {

    },
    methods: {
        async getExamResult() {
            let excelItems = [];
            var index = 1;
            this.testPreviewData.questions.forEach(x => {
                //console.log(x);
                var studentAnswer = x.answers.filter((x) => x.isSelected == true);

                studentAnswer = (studentAnswer.length > 0) ? studentAnswer[0] : { "text": "Boş Bıraktı" };
                excelItems.push({
                    "Soru Numarası": index,
                    "Kazanım": x.gains,
                    "Öğrencinin Cevabı": studentAnswer.text,
                    "Doğru Cevap": x.answers.filter((x) => x.isTrue == true)[0].text
                });
                index++;
            })
            let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, this.testPreviewData.title + ".xlsx");
        },
        async getTestPreview(data) {
            var testDettails = await this.$http.get(`Report/UserTestPreview/${data.testId}/${data.user.id}`);
            this.testPreviewData = testDettails.data.data;
            this.openDetailsModal = true;
        },

        async getTestData() {
            this.loading = true;
            var request = {
                lessonId: this.$props.lessonId,
                Class: [this.$props.classId],
                childLessonId: this.$props.childLessonId,
                topicId: this.$props.topicId,
                videoGroupId: this.$props.videoGroupId,
                testId: [this.$props.testId],
                status: 5
            };
            var lesson = await this.$http.post("Report/Test", request);
            this.users = lesson.data.data;
            this.getCalculator();
            this.loading = false;
        },
        async exportData() {
            let excelItems = [];
            this.users.forEach(x => {
                //console.log(x);
                excelItems.push({
                    "Öğrenci Ad Soyad": x.user.firstName + ' ' + x.user.lastName,
                    "Şube": x.class,
                    "Test": x.title,
                    "Doğru": x.correct,
                    "Yanlış": x.wrong,
                    "Boş": x.empty,
                    "Puan": x.point
                });
            })
            let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, "TestReport.xlsx");
        },
        getQuestionCount() {
            var testQuestion = [];
            this.users.forEach(element => {
                var testtt = testQuestion.filter(x => x.id == element.testId);
                if (testtt == null || testtt.length == 0) {
                    testQuestion.push({
                        id: element.testId,
                        count: element.correct + element.wrong + element.empty,
                        title: element.title
                    });
                }
            });
            return testQuestion;
        },

        getCalculator() {

            var correctOrt = 0;
            var emptyOrt = 0;
            var wrongOrt = 0;
            var pointOrt = 0;
            var totalOrt = 0;
            var qstr = this.getQuestionCount();

            this.users.forEach(element => {
                correctOrt += element.correct;
                emptyOrt += element.empty;
                wrongOrt += element.wrong;
                pointOrt += element.point;
                totalOrt += qstr.filter(x => x.id == element.testId)[0].count
            });

            this.ort.totalOrt = (totalOrt / this.users.length).toFixed(2);
            this.ort.correct = (correctOrt / this.users.length).toFixed(2);
            this.ort.empty = (emptyOrt / this.users.length).toFixed(2);
            this.ort.wrong = (wrongOrt / this.users.length).toFixed(2);
            this.ort.point = (pointOrt / this.users.length).toFixed(2);

        },

        getExamStatus(status) {
            switch (status) {
                case 'Open':
                    return 'primary'
                case 'Waiting':
                    return 'info'
                case 'Closed':
                    return 'secondary'
                case 'Processing':
                    return 'warning'
                case 'HasResulted':
                    return 'success'
            }
        },

        getExamStatusText(status) {
            switch (status) {
                case 'Open':
                    return "Başlamadı"
                case 'Waiting':
                    return "Devam Ediyor"
                case 'Closed':
                    return "Kapalı"
                case 'Processing':
                    return "Sonuçlar İşleniyor"
                case 'HasResulted':
                    return "Sonuçlandı"
            }
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
