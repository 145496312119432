<template>
<div>
    <b-card no-body class="card-company-table">
        <b-row class="mt-2">
            <b-col cols="12" md="6" class="mb-md-0">

            </b-col>
            <b-col cols="12" md="6" class="mb-md-0">
                <b-button target="_blank" block @click="downloadExcel()" variant="primary" class="font-weight-bolder mb-12">Excell İndir</b-button>
            </b-col>
        </b-row>
        <b-table :items="userData" responsive class="mb-0"></b-table>
    </b-card>
</div>
</template>

<script>
import XLSX from 'xlsx';
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BTabs,
    BTab
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay,
        BTabs,
        BTab
    },
    props: ["lessonId", "childLessonId", "topicId", "videoGroupId", "testId", "classId"],
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'testName', label: 'Testin Adı' },
                { key: 'studentCount', label: 'Toplam Öğrenci Sayısı' },
                { key: 'successCount', label: 'Başarılı Öğrenci' },
                { key: 'failCount', label: 'Başarısız Öğrenci' },
                { key: 'exitCount', label: 'Girmeyen Öğrenci' },
                { key: 'successRate', label: 'Başarı Yüzdesi' },
            ],
            questionFields: [
                { key: 'number', label: 'Soru' },
                { key: 'count', label: 'Toplam' },
                { key: 'referance', label: 'İşlem' },
            ],
            userSuccessFields: [
                { key: 'idNumber', label: 'Öğrenci Tc' },
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'empty', label: 'Boş Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'userPoint', label: 'Öğrencinin Puanı' }
            ],
            userFailFields: [
                { key: 'idNumber', label: 'Öğrenci Tc' },
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'failCount', label: 'Yanlış Sayısı' },
                { key: 'answers', label: 'Cevaplar' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'empty', label: 'Boş Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'userPoint', label: 'Öğrencinin Puanı' }
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            users: [],
            userData: [],
            downloadUrl: "",
        }
    },
    created() {
        this.getTestData();
    },
    methods: {
        async getTestData() {
            this.loading = true;
            var request = {
                lessonId: this.$props.lessonId,
                class: this.$props.classId,
                childLessonId: this.$props.childLessonId,
                topicId: this.$props.topicId,
                videoGroupId: this.$props.videoGroupId,
                testId: this.$props.testId,
            };

            var userData = await this.$http.post("Report/TestRatesUser", request);
            this.userData = userData.data.data.data;
            this.downloadUrl = userData.data.data.downloadUrl;
            this.loading = false;
        },

        async downloadExcel() {
            var ws_data = new Array();
            var lessonsrow = new Array();

            for (const key in this.userData[0]) {
                if (key != "idNumber") {
                    lessonsrow.push(key);
                } else if (key == "class") {
                    key = "Sınıf";
                } else if (key == "studentName") {
                    key = "İsim Soyisim";
                }
            }
            ws_data.push(lessonsrow);

            this.userData.filter(function (user) {

                var newrow = new Array();
                for (const key in user) {
                    if (key != "idNumber") {
                        newrow.push(user[key]);
                    }
                }

                ws_data.push(newrow);
            })

            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Percent Report",
                Subject: "Percent Report",
                Author: "Yes Akademi",
                CreatedDate: new Date()
            };

            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.SheetNames.push("Sayfa1");
            ws["!cols"] = [{ width: 20 }, { width: 30 }]; //student names width
            for (let index = 0; index < 30; index++) {
                ws["!cols"].push({ width: 25 });
            }

            wb.Sheets["Sayfa1"] = ws;
            await XLSX.writeFile(wb, "percentReport.xlsx");

        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
