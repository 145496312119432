<template>
<div>
    <b-card no-body class="card-company-table">
        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <!-- Id -->
            <template #cell(username)="data">
                <span class="font-weight-bolder mb-12">{{data.item.user.firstName+' '+data.item.user.lastName }}</span>
            </template>

            <template #cell(time)="data">
                <span class="font-weight-bolder mb-12">{{new Date(data.item.time * 1000).toISOString().substr(11, 8)}}</span>
            </template>
            <!-- Id -->
            <template #cell(percent)="data">
                <b-progress key="success" animated :value="data.item.percent" variant="success" class="progress-bar-success" />
            </template>

        </b-table>
    </b-card>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        vSelect
    },
    props: ["lessonId", "childLessonId", "topicId", "videoGroupId", "videoId", "classId"],
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'firstDate', label: 'İlk İzleme Tarihi' },
                { key: 'username', label: 'Öğrenci' },
                { key: 'video.name', label: 'Video' },
                { key: 'time', label: 'Kaldığı Süre' },
                { key: 'percent', label: 'İlerleme' },
            ],
            users: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true;
            var request = {
                lessonId: this.$props.lessonId,
                classId: this.$props.classId,
                childLessonId: this.$props.childLessonId,
                topicId: this.$props.topicId,
                videoGroupId: this.$props.videoGroupId,
                videoId: this.$props.videoId,
            };
            var lesson = await this.$http.post("Report/VideoWatching", request);
            this.users = lesson.data.data;
            this.loading = false;
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
