<template>
<div>
    <section id="knowledge-base-content">
        <b-overlay :show="loading" no-wrap> </b-overlay>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-body>
                        <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                            <b-button @click="prevPlan()" :disabled="selectedIndex == 0">◄ Önceki Hafta</b-button>
                            <h1>{{ getTitle }}</h1>
                            <b-button @click="nextPlan()" :disabled="selectedIndex == myCalendar.length - 1">Sonraki Hafta ►</b-button>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body class="card-company-table">
                    <b-card-body>
                        <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                            <b-button v-for="clas in getClass" :key="clas.classId" @click="sendWeekPlan({ type:'class',classId:clas.classId})">
                                {{clas.className}} Toplu Gönder
                            </b-button>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body class="card-company-table">
                    <b-card-body>
                        <app-collapse type="margin">
                            <app-collapse-item v-for="selec in selectedPlan.entries" :key="selec.id">
                                <template slot="header">
                                    <div style="flex: 1;display: flex;justify-content: space-between;">
                                        <span>{{ getSectionTitle(selec) }}</span>
                                        <b-button @click="getReport(selec)">
                                            Raporla
                                        </b-button>
                                    </div>
                                </template>
                                <b-table :items="selec.details" responsive :fields="childFields" class="mb-0">
                                    <template #cell(isSended)="data">
                                        <b-button block :variant="data.item.isSended ? 'flat-success':'flat-default'" class="font-weight-bolder mb-12">{{ data.item.isSended ? 'Gönderildi':'Gönderilmedi'}}</b-button>
                                    </template>
                                    <template #cell(action)="data">
                                        <b-button block :variant="data.item.isSended ? 'flat-success':'flat-default'" class="font-weight-bolder mb-12" @click="sendWeekPlan({ type:'single',detailId:data.item.id,item:data.item})">{{data.item.isSended ? 'Raporlar':'Ödev Gönder'}}</b-button>
                                    </template>
                                </b-table>
                            </app-collapse-item>
                        </app-collapse>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
    <b-modal v-model="singleReport" scrollable size="xl" hide-footer>
        <TestReportModal v-if="singleReport && selectSingleType =='Test'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :testId="selectedSingleReport.elementId" />
        <VideoReportModal v-if="singleReport && selectSingleType =='Video'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :videoId="selectedSingleReport.elementId" />
        <PercentReportModal v-if="singleReport && selectSingleType =='Percent'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :testId="selectedSingleReport.elementId" />
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BSidebar,
    BTooltip,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BCardText,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import SelectedStudent from "./PageComponent/SelectedStudent.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import "moment/locale/tr";
import TestReportModal from "./modals/TestReportModal.vue"
import VideoReportModal from "./modals/VideoReportModal.vue"
import PercentReportModal from "./modals/PercentReportModal.vue";
export default {
    components: {
        flatPickr,
        SelectedStudent,
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BBadge,
        BSidebar,
        BTooltip,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        BCardText,
        BListGroup,
        BListGroupItem,
        TestReportModal,
        VideoReportModal,
        PercentReportModal
    },
    data() {
        return {
            fields: [
                { key: "date", label: "Hafta" },
                { key: "lessonName", label: "Ders" },
                { key: "childLessonName", label: "Alt Ders" },
                { key: "topicName", label: "Konu" },
                { key: "videoGroupName", label: "Video Grup" },
                { key: "action", label: "İşlemler" },
            ],
            childFields: [
                { key: "className", label: "Sınıf" },
                { key: "elementType", label: "Ödev Tipi" },
                { key: "elementName", label: "Ödev" },
                { key: "isSended", label: "Durumu" },
                { key: "sendAt", label: "Gönderme Tarihi" },
                { key: "endAt", label: "Bitiş Tarihi" },
                { key: "action", label: "İşlemler" },
            ],
            myPlans: [],
            myCalendar: [],
            classList: [],
            selectedPlan: null,
            loading: false,
            selectedIndex: 0,
            singleReport: false,
            selectedSingleReport: null,
            selectSingleType:null
        };
    },
    computed: {
        getTitle() {
            if (this.selectedPlan == null)
                return "Öncelikle Plan Oluşturmanız Gerekli.";
            return (
                this.formattedDate(this.selectedPlan.startAt) +
                " - " +
                this.formattedDate(this.selectedPlan.endAt)
            );
        },
        getClass() {
            if (this.selectedPlan == null) return [];
            const allDetails = this.selectedPlan.entries.flatMap(item =>
                item.details
            );
            const uniqueClasses = Array.from(
                new Set(allDetails.map(item => item.classId))
            ).map(id => {
                return {
                    classId: id,
                    className: allDetails.find(item => item.classId === id).className
                };
            });
            return uniqueClasses;
        }
    },
    created() {
        this.getMyPlans();
    },
    methods: {
        async getMyPlans() {
            this.loading = true;
            var plans = await this.$http.get("Report/MyWeeklyPlan");
            this.myPlans = plans.data.data;

            const uniqueWeeks = Array.from(
                new Set(this.myPlans.map(item => `${item.year}-${item.week}`))
            ).map(key => {
                const [year, week] = key.split('-').map(Number);
                const groupItems = this.myPlans.filter(item => item.year === year && item.week === week);

                const startAt = groupItems.reduce((minDate, item) => new Date(item.startAt) < new Date(minDate) ? item.startAt : minDate, groupItems[0].startAt);

                const endAt = groupItems.reduce((maxDate, item) => new Date(item.endAt) > new Date(maxDate) ? item.endAt : maxDate, groupItems[0].endAt);

                return {
                    year: year,
                    week: week,
                    startAt: startAt,
                    endAt: endAt,
                    entries: groupItems
                };
            });

            this.myCalendar = uniqueWeeks;
            console.log(this.myCalendar);

            const date = new Date();
            const startOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - startOfYear) / 86400000;

            const currentWeek = Math.ceil(
                (pastDaysOfYear + startOfYear.getDay() + 1) / 7
            );

            this.selectedPlan = this.selectedPlan == null ?  this.myCalendar.find((x) => x.week == currentWeek): this.selectedPlan;
            this.selectedIndex = this.selectedIndex == 0 ? this.myCalendar.findIndex((x) => x.week == currentWeek) : this.selectedIndex;
            if (this.selectedPlan == null) {
                this.selectedIndex = 0;
                this.selectedPlan = this.myCalendar[0];
            }

            this.loading = false;
        },
        getSectionTitle(item) {
            return `${item.lessonName} - ${item.childLessonName} - ${item.topicName} - ${item.videoGroupName}`;
        },
        prevPlan() {
            const nextIndex = this.selectedIndex - 1;
            if (nextIndex >= 0) {
                this.selectedPlan = this.myCalendar[nextIndex];
                this.selectedIndex = nextIndex;
            }
        },
        nextPlan() {
            const nextIndex = this.selectedIndex + 1;
            if (nextIndex < this.myCalendar.length) {
                this.selectedPlan = this.myCalendar[nextIndex];
                this.selectedIndex = nextIndex;
            }
        },
        formattedDate(dateString) {
            return moment(dateString, "DD.MM.YYYY").format("DD MMMM YYYY");
        },
        async sendWeekPlan(req) {
            this.loading = true;
            const request = {
                week: this.selectedPlan.week,
                year: this.selectedPlan.year,
            };

            if (req.type == "class") {
                request.classId = req.classId;
            }

            if (req.type == "single") {
                request.detailId = req.detailId;
            }

            if (req.item?.isSended == true) {
                //open report
                const parent = this.selectedPlan.entries.find(entry => 
                    entry.details.some(detail => detail.id === req.item.id)
                );

                this.selectedSingleReport = {
                    classId:req.item.classId,
                    elementId:req.item.elementId,
                    lessonId:parent.lessonId,
                    childLessonId:parent.childLessonId,
                    topicId:parent.topicId,
                    videoGroupId:parent.videoGroupId
                };

                this.selectSingleType = req.item.elementType;

                this.singleReport = true;
                this.loading = false;
                return;
            }

            await this.$http.post("Report/SendWeeklyPlan", request);
            this.loading = false;
            this.getMyPlans();

            console.log(request);
        },
        getReport(data){
            console.log(data);
            let classIds = data.details.map(dt=>{
                if(dt.isSended == true)
                    return dt.classId
                else return null;
            });
            let testIds = data.details.map(dt=>{
                if(dt.elementType == "Test" && dt.isSended == true) return dt.elementId;
                else return null
            });

            testIds = testIds.filter(x=>x != null);
            classIds = classIds.filter(x=>x != null);
            classIds = [...new Set(classIds)];
            testIds = [...new Set(testIds)];

            if(testIds.length == 0){
                return;
            }

            this.selectedSingleReport = {
                classId:classIds,
                elementId:testIds,
                lessonId:data.lessonId,
                childLessonId:data.childLessonId,
                topicId:data.topicId,
                videoGroupId:data.videoGroupId
            };

            this.selectSingleType = "Percent";

            this.singleReport = true;
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}

.scroll {
    max-height: 500px;
    overflow-y: auto;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.item span {
    font-weight: bold;
}
</style>
